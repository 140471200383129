import React, {memo, useState} from "react";
import {graphql} from "gatsby"
import * as styles from './product-line.module.css'
import get from "lodash/get";
import Layout from "../components/layout";
import cls from 'classnames';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {navigate} from "gatsby"
import SEO from "../components/seo";
import {useLang} from "../hooks/useLang";

const getProductName = (name) => {
  return name.length > 40 ? (name.slice(0, 40) + '...') : name
}

const Item = memo(({product, index}) => {
  return (
    <div className="col-3 p-3">
      <div key={index} className={cls("card", styles.productItem)}>
        <AniLink to={`/ar/product/${product.link.trim().replace(' ', '-').toLowerCase()}`} duration={0.3} hex="#9dc428"
                 paintDrip>
          <div className={styles.imgWp}>
            <img className="w-100 p-2" src={product && product.imageLink[0].fluid.src}/>
          </div>
          <div className={styles.line}/>
          <div className="card-body text-center">
          <span className={cls(styles.linkSpan)}>
            {product && getProductName(product.name ?? product.link)}
          </span>
          </div>
        </AniLink>
      </div>
    </div>
  )
})

const ProductLine = ({data, location}) => {
  const productLines = get(data, 'allContentfulProductLine.nodes')
  const bannerLink = get(data, 'contentfulAsset.fluid.src')
  const allKeywords = get(data, 'allContentfulPageKeywords.nodes')
  const lines = productLines
  const params = new URLSearchParams(location.search);
  const tag = params.get("tag");
  const passIndex = lines.findIndex(x => x.identity === tag)
  const [currentIndex, setCurrentIndex] = useState(passIndex === -1 ? 0 : passIndex);


  const renderNav = () => {
    lines.forEach(x => {
      x.products.forEach(y => {
        if (typeof window !== 'undefined') {
          const image = new Image(100, 200)
          image.src = y.imageLink[0].fluid.src
        }
      })
    })
    return lines.map(x => x.name).map((name, index) => {
      return (
        <a key={index}
           className={`${styles.pageNvaItem} ${currentIndex === index ? styles.pageNvaItemActive : ''}`}
           onClick={() => {
             navigate(`/ar/product-line?tag=${name.trim().replace(' ', '-').toLowerCase()}`);
             setCurrentIndex(index)
           }}>
             <span>
           {name}
             </span>
        </a>
      )
    })
  }

  return (
    <Layout location={location}>
      <SEO title={'Product line'} keywords={allKeywords[0]?.keywords}/>
      <div className={styles.bannerWrapper}>
        <img src={bannerLink} className="img-fluid" alt="Product line page banner"/>
      </div>
      <section className="container w-75">
        <div className={styles.pageNvaBody}>
          <div className="p-2">
            <div className="container justify-content-center">
              <div className="d-flex flex-row justify-content-around p-3">
                {renderNav()}
              </div>
              <div className={styles.navBody}>
                {
                  <div className="container d-flex flex-wrap p-2 justify-content-start">
                    {
                      lines[currentIndex].products && lines[currentIndex].products.map((x, index) => <Item
                        index={index}
                        product={x}/>)
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container w-75 p-4">
        <div className={cls("row p-4", styles.more)}>
          <div className="col-9" style={{lineHeight: '4rem'}}>Searching For A
            {/*البحث عن الشركة المصنعة لمقبس توصيل الطاقة؟*/}
            البحث {lines[currentIndex].name}الطاقة؟
          </div>
          <div className={cls("col-3", styles.moreBtn)}>
            <AniLink to={`/ar/contact-us`} duration={0.3} hex="#9dc428" paintDrip>
              <span style={{color: "var(--main-color)"}} className={"p-2"}>
                ابقى على تواصل
              </span>
            </AniLink>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductLine;

export const pageQuery = graphql`
    query ProductLineARQuery {
        allContentfulProductLine(filter: {node_locale: {eq: "ar"}}) {
            nodes {
                imageLink {
                    fluid {
                        src
                    }
                }
                id
                identity
                name
                products {
                    id
                    link
                    name
                    imageLink {
                        fluid {
                            src
                        }
                        id
                        title
                        description
                    }
                    price
                    type
                }
            }
        }
        allContentfulBanner(sort: { fields: [name], order: DESC }) {
            edges {
                node {
                    name
                    imageLink {
                        file {
                            url
                        }
                        fluid(quality: 90, maxWidth: 1920) {
                            src
                        }
                    }
                    type
                }
            }
        }
        allContentfulPageKeywords(filter: {page: {eq: "products"}, node_locale: {eq: "ar"}}) {
            nodes {
                keywords
                node_locale
                page
            }
        }
        contentfulAsset(title: {eq: "product_banner"}) {
            fluid(quality: 90, maxWidth: 1920) {
                src
            }
        }
    }
`

